// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-padel-js": () => import("./../../../src/pages/about-padel.js" /* webpackChunkName: "component---src-pages-about-padel-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-footer-js": () => import("./../../../src/pages/footer.js" /* webpackChunkName: "component---src-pages-footer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-navbar-js": () => import("./../../../src/pages/navbar.js" /* webpackChunkName: "component---src-pages-navbar-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-seo-js": () => import("./../../../src/pages/seo.js" /* webpackChunkName: "component---src-pages-seo-js" */),
  "component---src-pages-top-jumbotron-js": () => import("./../../../src/pages/top-jumbotron.js" /* webpackChunkName: "component---src-pages-top-jumbotron-js" */),
  "component---src-pages-top-text-notification-js": () => import("./../../../src/pages/top_text_notification.js" /* webpackChunkName: "component---src-pages-top-text-notification-js" */)
}

